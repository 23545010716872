import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import ActionMenu from "../../components/ActionMenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { TabMenu } from "primereact/tabmenu";
import { Paginator } from "primereact/paginator";

import AddViolateDialog from "./ModelAddViolate";
import EditViolateDialog from "./ModelEditViolate";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getViolateByUserId } from "../../store/violate/actions";

import moment from "moment";
import { site } from "../../api/url_helper";
import ViolateDetail from "../ViolateDetail/index";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Violate() {
  const location = useLocation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [rowData, setRowData] = useState();
  const [expandedRows, setExpandedRows] = useState(null);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const toast = useRef(null);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [timeLine, setTimeLine] = useState(false);
  const [selectRowInternTimeLine, setSelectRowInternTimeLine] = useState("");
  const [global, setGlobal] = useState("");

  const dispatch = useDispatch();

  const { violateData, dataUpdate, dataInsert } = useSelector(
    (state) => ({
      violateData: state.Violate.dataByUserId,
      dataUpdate: state.Violate.dataUpdate,
      dataInsert: state.Violate.dataInsert,
    }),
    shallowEqual
  );

  const { t } = useTranslation();

  useEffect(() => {
    let tab = "All";
    if (violateData) {
      tab = violateData.menu[tabMenuActive].label;
    }
    // cho 1s sau khi lay du lieu tim kiem tu bien global roi dispatch
    const timeout = setTimeout(() => {
      dispatch(getViolateByUserId({ page: (first + rows) / rows, limit: rows, tab, s_global: global }));
    }, 1000);

    return () => clearTimeout(timeout);
    // dispatch(getViolateByUserId({ page: (first + rows) / rows, limit: rows, tab }));
  }, [dispatch, first, tabMenuActive, rows, dataUpdate, dataInsert, global]);

  useEffect(() => {
    const updateDataTable = () => {
      if (violateData) {
        setDataTable(violateData.data);
      }
    };
    updateDataTable();
  }, [violateData]);

  const admin = "syndication";

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  const handleTimeLine = (rowData) => {
    setSelectRowInternTimeLine(rowData);
    setTimeLine(true);
  };

  //Xóa dữ liệu
  const handleDelete = (rowData) => {};

  const nameTemplete = (rowData) => {
    return (
      <div className="flex flex-row align-items-center py-2">
        <Avatar image={`${rowData?.intern_info?.avata}`} shape="circle" className="mr-3" />
        <div className="flex flex-column">
          <span>{`${rowData?.intern_info?.first_name_jp} ${rowData?.intern_info?.middle_name_jp} ${rowData?.intern_info?.last_name_jp}`}</span>
          <span>{`${rowData?.intern_info?.first_name_en} ${rowData?.intern_info?.middle_name_en} ${rowData?.intern_info?.last_name_en}`}</span>
        </div>
      </div>
    );
  };

  const dateTemplate = (rowData) => {
    return (
      <div className="flex flex-column">
        <span className="font-bold">{moment(rowData?.violate_date).utcOffset(+9).format("YYYY/MM/DD")}</span>
        <span>{moment(rowData?.violate_date).utcOffset(+9).format("HH:mm")}</span>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data?.violate_info} showHeaders={false}>
          <Column className="bg-green-50" field="name" header="Intern name" body={nameTemplete}></Column>
          {admin !== "syndication" && (
            <Column className="bg-green-50" field="intern_info.syndication_info.name_jp" header="Syndication"></Column>
          )}
          {admin !== "factory" && (
            <Column
              className="bg-green-50"
              field="intern_info.receiving_factory_info.name_jp"
              header="Factory"
            ></Column>
          )}
          {admin !== "dispatching" && (
            <Column
              className="bg-green-50"
              field="intern_info.dispatching_company_info.name_jp"
              header="Dispatching"
            ></Column>
          )}
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData?.violate_info?.length > 0;
  };

  const statusTemplate = (rowData) => {
    switch (rowData?.violate_status) {
      case "New":
        return <Tag value={t(rowData?.violate_status)} severity="success"></Tag>;
      case "Processing":
        return <Tag value={t(rowData?.violate_status)} severity="warning"></Tag>;
      case "Done":
        return <Tag value={t(rowData?.violate_status)} severity="info"></Tag>;
      default:
        return rowData.violate_status;
    }
  };

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        onAddHandleViolate={() => handleTimeLine(rowData)}
        type="violate"
      />
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="flex flex-row justify-content-between mb-3">
        <Button label={t("Add New")} size="small" onClick={() => setDialogVisible(true)} />
        <IconField iconPosition="left" className="w-6">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            v-model="value1"
            placeholder="Search by name intern or day violate"
            className="w-full"
            value={global}
            onChange={(e) => setGlobal(e.target.value)}
          />
        </IconField>
      </div>
      <div className="bg-white  border-round-md shadow-1 px-3">
        <TabMenu
          model={violateData?.menu?.map((item, index) => ({
            ...item,
            template: () => tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
          }))}
          activeIndex={tabMenuActive}
          onTabChange={(e) => setTabmenuActive(e.index)}
          pt={{ menuitem: "p-0" }}
        />
        <DataTable
          value={dataTable}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          emptyMessage={t("No data available")}
          size="small"
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column field="violate_date" header={t("Date")} body={dateTemplate} style={{ width: "10%" }}></Column>
          <Column field="violate_type_info.name" header={t("Violate type")} style={{ width: "15%" }}></Column>
          <Column field="violate_address" header={t("Address Information")} style={{ width: "15%" }}></Column>
          <Column
            field="description"
            header={t("Description")}
            style={{ width: "50%", paddingTop: "3px", paddingBottom: "3px" }}
          ></Column>
          <Column field="status" header={t("Status")} body={statusTemplate} style={{ width: "10%" }}></Column>
          <Column field="action" body={actionBodyTemplate}></Column>
        </DataTable>
        <div className="card">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={violateData?.total}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      {/* {dialogVisible && (
      )} */}
      {dialogVisible && (
        <AddViolateDialog visible={dialogVisible} onHide={() => setDialogVisible(false)} toast={toast} />
      )}
      {timeLine && (
        <ViolateDetail
          visible={timeLine}
          onHide={() => setTimeLine(false)}
          toast={toast}
          internData={selectRowInternTimeLine}
        />
      )}
      {dialogEditVisible && (
        <EditViolateDialog
          visible={dialogEditVisible}
          onHide={() => setDialogEditVisible(false)}
          rowData={rowData}
          toast={toast}
        />
      )}
    </>
  );
}

export default Violate;
