import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import AddStatusDialog from "./ModelAddStatus";
import EditStatusDialog from "./ModelEditStatus";
import ActionMenu from "../../../components/ActionMenu";
import ModelDelete from "../../../components/ModalDelete";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteStatus, getStatusByUserId, setDataNull } from "../../../store/status/actions";
import { FilterMatchMode } from "primereact/api";

import { useTranslation } from "react-i18next";

function Status() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const toast = useRef(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { dataStatus, loadingInsert, loadingUpdate, loadingDelete, dataDelete, errorDelete } = useSelector(
    (state) => ({
      dataStatus: state.Status.dataByUserId,
      loadingInsert: state.Status.loadingInsert,
      loadingUpdate: state.Status.loadingUpdate,
      loadingDelete: state.Status.loadingDelete,
      dataDelete: state.Status.dataDelete,
      errorDelete: state.Status.errorDelete,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!loadingInsert || !loadingUpdate) {
      dispatch(getStatusByUserId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadingInsert, loadingUpdate, loadingDelete]);

  useEffect(() => {
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorDelete.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataDelete) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Delete status success",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line
  }, [errorDelete, dataDelete]);

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  //Xóa dữ liệu
  const handleDelete = (data) => {
    setModalDelete(true);
    setRowData(data);
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteStatus(id));
      }
      setModalDelete(false);
    }
  };

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return <ActionMenu onEdit={() => handleEdit(rowData)} onDelete={() => handleDelete(rowData)} />;
  };

  // Lọc dữ liệu
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    description: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-row justify-content-between mb-3">
        <Button label={t("Add New")} size="small" onClick={() => setDialogVisible(true)} disabled={!role} />
        <IconField iconPosition="left" className="w-6">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            v-model="value1"
            placeholder="Search"
            className="w-full"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
          />
        </IconField>
      </div>
      <div className="bg-white  border-round-md shadow-1 p-3">
        <DataTable
          value={dataStatus}
          paginator
          emptyMessage={t("No data available")}
          rows={10}
          filters={filters}
          globalFilterFields={["name"]}
          rowsPerPageOptions={[10, 20, 50, 100]}
        >
          <Column field="no" header={t("No")} body={(data, options) => options.rowIndex + 1} className="w-1"></Column>
          <Column field="name" header={t("Status Name")} className="w-3"></Column>
          {/* <Column field="is_auto" header="Auto" className="w-3"></Column> */}
          <Column field="description" header={t("Description")} className="w-7"></Column>
          <Column field="action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <AddStatusDialog visible={dialogVisible} onHide={() => setDialogVisible(false)} toast={toast} />
      <EditStatusDialog
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        toast={toast}
        rowData={rowData}
      />
      <ModelDelete visible={modalDelete} setVisible={setModalDelete} accept={acceptDelete} />
    </>
  );
}

export default Status;
