import React from "react";
import { Badge } from "primereact/badge";
import { useTranslation } from "react-i18next";

const TabMenuItem = ({ item, itemIndex, tabMenuActive, setTabmenuActive }) => {
  const { t } = useTranslation();

  return (
    <div
      className="p-menuitem-link flex align-items-center gap-2 cursor-pointer"
      onClick={() => setTabmenuActive(itemIndex)}
      style={{
        width: window.innerWidth <= 768 ? "8rem" : "auto",
        cursor: "pointer",
      }}
    >
      <span
        className={`white-space-nowrap overflow-hidden ${tabMenuActive === itemIndex ? "font-bold text-primary" : ""}`}
      >
        {t(item.label)}
      </span>
      <Badge
        value={item.count}
        style={{
          backgroundColor: tabMenuActive === itemIndex ? "#10B981" : "#999999",
        }}
      ></Badge>
    </div>
  );
};

const tabMenuTemplate = (item, itemIndex, tabMenuActive, setTabmenuActive) => (
  <TabMenuItem
    key={itemIndex}
    item={item}
    itemIndex={itemIndex}
    tabMenuActive={tabMenuActive}
    setTabmenuActive={setTabmenuActive}
  />
);

export default tabMenuTemplate;
