import React, { useRef, useState, useContext, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { Badge } from "primereact/badge";
import { Divider } from "primereact/divider";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import SelectLanguage from "../../../SelectLanguage";
import SidebarMenu from "../SidebarMenu";

import { DataContext } from "../../../../contexts/data/DataProvider";

import axios from "axios";
import { site } from "../../../../api/url_helper";
import { logout } from "../../../../store/auth/actions";
import { getNotify } from "./../../../../store/notify/actions";
import CircleImage from "../../../CircleImage";

import socketInstance from "../../../../Singleton/SocketSingleton";

function Header() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const accountMenu = useRef(null);
  const { t } = useTranslation();
  const [rows, setRows] = useState(10);
  const [visible, setVisible] = useState(false);
  const auth = JSON.parse(localStorage.getItem("auth"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedItem } = useContext(DataContext);

  const [information, setInformation] = useState({});

  const { notify } = useSelector(
    (state) => ({
      notify: state.Notify.notify,
    }),
    shallowEqual
  );

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const socket = socketInstance.getSocket();

    // Lắng nghe sự kiện "get-message"
    socket.on("get-message", (msg) => {
      console.log("Received message: ", msg);
      // Cập nhật state với tin nhắn mới
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    // Cleanup function để loại bỏ sự kiện lắng nghe khi component unmount
    return () => {
      socket.off("get-message");
    };
  }, []);

  useEffect(() => {
    try {
      if (auth) {
        axios.get(`${site}/api/users/get-info`, { withCredentials: true }).then((res) => {
          let result = res.data;
          setInformation(result.data);
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const notificationScroller = document.getElementById("notification-scroller");
  document.addEventListener("click", (event) => {
    if (notificationScroller && !notificationScroller?.contains(event.target)) {
      setVisible(false);
    }
  });

  useEffect(() => {
    if (information && auth) {
      dispatch(getNotify({ page: 1, limit: rows }));
    }
  }, [information, rows, dispatch, visible, messages]);

  const fetchMoreData = () => {
    setTimeout(() => {
      setRows(rows + 5);
    }, 500);
  };

  const profileMenuItems = [
    {
      label: "Profile",
      items: [
        {
          label: t("Account information"),
          icon: "pi pi-info-circle",
          command: async () => {
            navigate("/profile");
          },
        },
        {
          label: t("Change password"),
          icon: "pi pi-refresh",
          command: async () => {
            navigate("/change-password");
          },
        },
        {
          label: t("Logout"),
          icon: "pi pi-sign-out",
          command: async () => {
            dispatch(logout());
          },
        },
      ],
    },
  ];

  const formatTime = (time) => {
    const currentDate = new Date();
    const targetDate = new Date(time);

    const timeDiff = Math.abs(currentDate - targetDate);
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    if (minutesDiff < 1) {
      return t("1 minute ago");
    } else if (minutesDiff < 60) {
      return `${minutesDiff} ${t("minutes ago")}`;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
      const hours = targetDate.getHours();
      const minutes = targetDate.getMinutes();
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    } else if (targetDate.getFullYear() === currentDate.getFullYear()) {
      const month = (targetDate.getMonth() + 1).toString().padStart(2, "0");
      const day = targetDate.getDate().toString().padStart(2, "0");
      return `${month}/${day}`;
    } else {
      const year = targetDate.getFullYear();
      const month = (targetDate.getMonth() + 1).toString().padStart(2, "0");
      const day = targetDate.getDate().toString().padStart(2, "0");

      return `${year}/${month}/${day}`;
    }
  };

  const avata = "./images/notification/notification-new.svg";

  const itemRenderer = (data) => (
    <div className={`p-menuitem-content ${data.seen ? "" : "surface-200"} `}>
      <div className="flex align-items-between p-menuitem-link gap-3 p-3">
        <img
          src={data.sender_info.avata || avata}
          alt=""
          style={{ borderRadius: "100%", width: "40px", height: "40px" }}
        />
        <div className="flex flex-column gap-1 w-10">
          <span className="font-bold white-space-nowrap overflow-hidden text-overflow-ellipsis w-full">
            {data.title.toUpperCase()}
          </span>
          <div className="flex flex-column  w-12">
            <span className="white-space-nowrap text-sm overflow-hidden text-overflow-ellipsis">{data.content}</span>
            <div className="flex mt-1">
              <span className="white-space-nowrap overflow-hidden text-overflow-ellipsis w-10 text-xs">
                {data.sender_info.name}
              </span>
              <span className="text-sm white-space-nowrap w-2 flex justify-content-end">
                {formatTime(data.send_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Divider className="m-0" />
    </div>
  );

  return (
    <>
      <div className="flex align-items-center justify-content-between bg-white h-4rem md:px-5">
        <div className="flex flex-row align-items-center">
          <div onClick={() => setMobileMenuVisible(true)}>
            <i className="pi pi-bars p-3 cursor-pointer md:hidden" style={{ fontSize: "1.5rem" }}></i>
          </div>
          <h3>{selectedItem}</h3>
        </div>
        <div className="flex flex-row align-items-center">
          <SelectLanguage />
          <div
            id="notification-scroller"
            className="p-3 cursor-pointer relative"
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <div
              className={`${
                visible ? "" : "hidden"
              } absolute top-100 bg-white right-50 shadow-8 border-round-md z-5 scroller-component `}
              style={{ minWidth: "500px", maxWidth: "600px" }}
            >
              <h4 className="pl-2 py-3 bg-white text-lg border-round-top-md">{t("Notification")}</h4>
              <InfiniteScroll
                dataLength={notify?.data?.length || 0}
                hasMore={rows === notify?.data?.length}
                next={fetchMoreData}
                className="overflow-auto border-round-md top-100 bg-white"
                height={500}
                useWindow={false}
              >
                {notify &&
                  notify.data?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          axios
                            .post(
                              `${site}/api/notification/seen`,
                              {
                                notification_id: item.id,
                              },
                              { withCredentials: true }
                            )
                            .then((res) => {
                              dispatch(getNotify({ page: 1, limit: rows + 5 }));
                            });
                          switch (item.type) {
                            case "intern":
                              navigate("/intern");
                              break;
                            case "announced":
                              navigate("/notification");
                              break;
                            case "ticket":
                              navigate("/support");
                              break;
                            case "violate":
                              navigate("/violate");
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {itemRenderer(item)}
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </div>
            <i className="pi pi-bell p-overlay-badge cursor-pointer" style={{ fontSize: "1.5rem" }}>
              <Badge
                value={notify && notify?.total_not_seen > 99 ? "99+" : notify?.total_not_seen}
                className={notify?.total_not_seen !== 0 ? "" : "hidden"}
              ></Badge>
            </i>
          </div>
          <div
            className="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700"
            onClick={(event) => accountMenu.current.toggle(event)}
          >
            <Menu model={profileMenuItems} popup ref={accountMenu} className="w-auto w-50" pt={{ menuitem: "mx-2" }} />
            <CircleImage src={information && information.avata} size={30} altImage="Avatar" />
            <span className="font-bold">{information.username}</span>
          </div>
        </div>
      </div>

      {/* SidebarMenu Mobile */}
      <div className="card flex justify-content-center">
        <Sidebar
          header={
            <div className="pl-3">
              <Image src="/images/logo/logo.png" alt="Image" width="50" />
            </div>
          }
          visible={mobileMenuVisible}
          onHide={() => setMobileMenuVisible(false)}
        >
          <SidebarMenu />
        </Sidebar>
      </div>
    </>
  );
}
export default Header;
